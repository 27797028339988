import GraphqlTable from '@/components/graphqlTable';
import OverflowTypography from '@/components/overflowTypography';
import { CheckPermissions, permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Box } from '@mui/material';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import { InvoicesRead } from '../../../../data/commerce/invoice.graphql';
import type { Order, QueryInvoicesReadArgs } from '../../../../types/schema';
import MobileRowPanel from '../components/mobileRow';
import { CalculateClients, CalculateDate } from '../components/tableHelpers';
import { InvoiceTemplateTableActions, useInvoiceTemplateActions } from './actions/templateActions';
import RowPanel from './rowPanel';

export const columns: Column<Order>[] = [ {
	accessor      : 'id',
	Header        : 'Id',
	disableSortBy : true,
	disableFilters: false,
	width         : 20,
	props         : { style: { minWidth: 50 } },
}, {
	accessor      : 'metadata',
	Header        : 'Name',
	width         : 20,
	disableFilters: false,
	props         : { style: { minWidth: 168 } },
	Cell          : ( { value } ) => value?.templateName,
}, {
	accessor       : ( row ) => row.client?.name || row.client?.contact,
	Header         : 'common:client',
	filterByKey    : 'client.contact',
	multipleFilters: [ 'name', 'contact' ],
	width          : 20,
	props          : { style: { minWidth: 168 } },
	Cell           : ( { value } ) => value ? ( (
		<OverflowTypography>{value}</OverflowTypography>
	) as any ) : '-',
	Footer         : ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, {
	accessor: 'updatedAt',
	Header  : 'Updated',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 168 } },
	Footer  : ( { rows } ) => CalculateDate( rows, 'updatedAt' ),
} ];

export default function InvoiceTemplateTable() {
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	return (
		<CheckPermissions redirect='/dashboard/commerce' permissions={permissions.invoices.read}>
			<ResponsiveModalContainer
				title={t( 'commerce:invoice-templates' )}
				secondaryTitle={t( 'commerce:create-templates-use-every-time' )}>
				<Head><title>{t( 'commerce:invoice-template' )}| Invoiss</title></Head>
				<Box display='flex' justifyContent='end'>
					<InvoiceTemplateTableActions/>
				</Box>
				<GraphqlTable<Order, QueryInvoicesReadArgs>
					hideColumnFilters
					searchable
					queryKey='invoices'
					showFooter={false}
					query={InvoicesRead}
					variables={{
						options: {
							filter: { metadata: { templateName: { $ne: null } } },
						},
					}}
					columns={columns}
					subscription={{ INVOICE: staff.company.id }}
					initialState={{ hiddenColumns: [ 'id' ] }}
					expandedComponent={( row ) => <RowPanel hideTags row={row}/>}
					useActions={useInvoiceTemplateActions}
					mobileRenderRow={( invoice ) => <MobileRowPanel data={invoice as any}/>}
				/>
			</ResponsiveModalContainer>
		</CheckPermissions>
	);
}
