import { differenceInMinutes } from 'date-fns';
import { keyBy } from 'lodash-es';
import { Maybe } from '../types/schema';

type UpstreamItem = {
	id: string,
	modifiedTime: number
};
type DbItem = {
	externalId?: Maybe<string>,
	syncDate?: string
};

export const findItemsToSync = ( upstreamItems: UpstreamItem[], dbItems: DbItem[] ) => {
	const upStreamItemsMap = keyBy( upstreamItems, 'id' );
	const dbItemsMap = keyBy( dbItems, 'externalId' );
	const unSyncedItems: UpstreamItem[] = [];
	
	for ( const id in upStreamItemsMap ) {
		const item = upStreamItemsMap[ id ];
		const dbSyncDate = dbItemsMap[ id ]?.syncDate;
		if ( !dbSyncDate || differenceInMinutes( new Date( item.modifiedTime ), new Date( dbSyncDate ) ) >= 1 ) {
			unSyncedItems.push( item );
		}
	}
	
	return unSyncedItems;
};
