import Actions, { ActionProps } from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CustomFieldsRead } from '@/data/commerce/customField.graphql';
import { InvoiceDuplicate, InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { AgreementsRead } from '@/data/management/agreement.graphql';
import { LineItemsRead } from '@/data/management/lineItem.graphql';
import { PricesRead } from '@/data/management/price.graphql';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import useWriteLog from '@/pages/dashboard/commerce/components/utils';
import { deleteMergedTo, lineItemPropertiesToOmit } from '@/pages/dashboard/commerce/invoices/actions/invoiceUtils';
import { InvoiceCreateAgreementsRead } from '@/pages/dashboard/commerce/invoices/invoiceGQL';
import { useLocations } from '@/pages/formSelects/locationSelect';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import {
	MutationInvoiceDuplicateArgs,
	MutationInvoiceWriteArgs,
	Order,
	PriceBase,
	QueryAgreementsReadArgs,
	QueryCustomFieldsReadArgs,
	QueryLineItemsReadArgs,
	QueryPricesReadArgs,
} from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import {
	AddCircle as AddCircleIcon,
	CopyAll as CopyAllIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	FindInPage as FindInPageIcon,
	PictureAsPdf as PictureAsPdfIcon,
} from '@mui/icons-material';
import { Theme, useMediaQuery } from '@mui/material';
import { add } from 'date-fns';
import { isEmpty, omit, pick, toLower, upperFirst } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export function InvoiceTemplateTableActions() {
	const { t } = useTranslation();
	const router = useRouter();
	const { closeModal } = useModalControls();
	const creatable = usePermissions( permissions.invoices.write );
	const { staff } = useUserInfo();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const [ locations ] = useLocations();
	
	return (
		<Actions
			separated
			max={+isMobile}
			items={[
				creatable && {
					name       : t( 'commerce:create-template' ),
					icon       : <AddCircleIcon/>,
					buttonProps: { variant: 'contained', color: 'primary' },
					onClick    : async () => {
						try {
							const { pricesRead } = await queryGraphQL<QueryPricesReadArgs>( {
								query    : PricesRead,
								variables: {
									options: {
										limit : 1,
										filter: {
											company   : staff?.company?.id,
											metadata  : { companyFee: true },
											isSavedFee: true,
											order     : null,
										},
									},
								},
							} );
							const { customFieldsRead } = await queryGraphQL<QueryCustomFieldsReadArgs>( {
								query    : CustomFieldsRead,
								variables: {
									options: {
										limit : 30,
										filter: {
											company: staff?.company?.id,
											order  : null,
										},
									},
								},
							} );
							const customFields = customFieldsRead?.items;
							
							const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
								query    : InvoiceCreateAgreementsRead,
								variables: {
									options: {
										limit : 1,
										filter: {
											isDefault: true,
										},
									},
								},
							} );
							const agreements = agreementsRead?.items;
							const { invoiceWrite } = await mutateGraphQL<MutationInvoiceWriteArgs>( {
								mutation : InvoiceWrite,
								variables: {
									method: 'Created Invoice Template',
									input : {
										type           : 'INVOICE',
										staff          : staff.id,
										companyLocation: locations[ 0 ]?.id || null,
										standingDate   : null,
										dueDate        : null,
										serviceDate    : null,
										metadata       : { templateName: 'Template 1' },
										customFields   : customFields?.map( ( customField ) => ( {
											...pick( customField, [ 'name', 'value' ] ),
											company: staff.company.id,
										} ) ),
										agreements     : agreements?.map( ( agreement ) => ( {
											...pick( agreement, [
												'title',
												'body',
												'requireSignature',
												'expiration',
											] ),
											company: staff.company.id,
										} ) ),
										prices         : pricesRead?.items?.map( ( price: PriceBase ) => ( {
											...omit( price, [ 'id', 'company' ] ),
										} ) ),
									},
								},
							} );
							closeModal();
							await router.push( `/dashboard/commerce/invoices/${invoiceWrite.id}/edit` );
							
						} catch ( e ) {
							throw e;
						}
					},
				},
			]}
		/>
	);
}

export function useInvoiceTemplateActions( invoice: Order, single?: boolean ) {
	const editable = usePermissions( permissions.invoices.write );
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	const confirmDialog = useConfirmDialog();
	const { closeModal } = useModalControls();
	const { staff, user } = useUserInfo();
	const { isCloverDevice } = useGetDeviceInfo();
	const { t } = useTranslation();
	const metadata = staff?.company?.metadata;
	const logWrite = useWriteLog();
	const type = upperFirst( toLower( invoice.type ) );
	const timezone = getBrowserTimezone();
	
	return [
		editable && {
			name   : t( 'common:use' ),
			icon   : <CopyAllIcon/>,
			onClick: async () => {
				try {
					const { invoiceDuplicate } = await mutateGraphQL<MutationInvoiceDuplicateArgs>( {
						mutation : InvoiceDuplicate,
						variables: {
							ids: [ invoice.id ],
						},
					} );
					const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
						query    : LineItemsRead,
						variables: { options: { limit: 1000, filter: { order: invoice.id } } },
					} );
					const lineItems = lineItemsRead?.items;
					const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
						query    : AgreementsRead,
						variables: { options: { limit: 1000, filter: { order: invoice.id } } },
					} );
					const agreements = agreementsRead?.items;
					await mutateGraphQL<MutationInvoiceWriteArgs>( {
						mutation : InvoiceWrite,
						variables: {
							id          : invoiceDuplicate.id,
							customNumber: Boolean( staff.company.metadata?.customNumber ),
							method      : 'New Invoice Template',
							input       : {
								type        : 'INVOICE',
								title       : invoice.title,
								standingDate: new Date(),
								dueDate     : add( new Date(), { days: metadata?.dueDate || metadata?.serviceDateDue || 0 } ),
								serviceDate : new Date(),
								metadata    : {
									templateName    : null,
									mergedSignatures: null,
								},
								prices      : !isEmpty( invoiceDuplicate.prices )
									? invoiceDuplicate.prices.filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' && name !== 'Credit' )
										.map( ( { id } ) => ( { id } ) )
									: [],
								agreements  : agreements?.map( ( agreement ) => ( {
									...pick( agreement, [
										'title',
										'body',
										'requireSignature',
										'expiration',
									] ),
									company: agreement.company.id || staff.company.id,
								} ) ),
								lineItems   : lineItems?.map( ( lineItem ) => ( {
									...pick( lineItem, [
										'name',
										'price',
										'image',
										'unit',
										'quantity',
										'tax',
										'sequence',
										'metadata',
										'orderTax',
									] ),
									description   : `${lineItem.description || ''}`,
									modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
									prices        : lineItem.prices?.map( ( price ) =>
										pick( price, [
											'name',
											'isPercent',
											'value',
											'quantity',
											'metadata',
										] ) ),
									uom           : lineItem.uom?.id || null,
									item          : lineItem.item?.id || null,
									category      : lineItem.category?.id || null,
								} ) ),
							},
						},
					} );
					closeModal();
					await router.push( `/dashboard/commerce/invoices/${invoiceDuplicate.id}/edit` );
				} catch ( e ) {
					// showModal( SubscriptionModal, { maxWidth: 'xs' } );
					throw e;
				}
			},
		},
		editable && {
			name   : t( 'common:edit' ),
			icon   : <EditIcon/>,
			onClick: () => closeModal(),
			props  : {
				component: PageLinkComponent,
				href     : `/dashboard/commerce/invoices/${invoice.id}/edit`,
			},
		}, !single && {
			name   : t( 'common:view' ),
			icon   : <FindInPageIcon/>,
			onClick: () => closeModal(),
			props  : {
				component: PageLinkComponent,
				href     : `/dashboard/commerce/invoices/${invoice.id}`,
			},
		}, editable && {
			name   : t( 'common:make-copy' ),
			icon   : <CopyAllIcon/>,
			onClick: async () => {
				try {
					const value = await confirmDialog( {
						title  : t( 'common:copy-template' ),
						message: t( 'common:copy-template-confirmation' ),
					} );
					if ( !value ) return;
					const { invoiceDuplicate } = await mutateGraphQL<MutationInvoiceDuplicateArgs>( {
						mutation : InvoiceDuplicate,
						variables: {
							ids: [ invoice.id ],
						},
					} );
					const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
						query    : LineItemsRead,
						variables: { options: { limit: 1000, filter: { order: invoice.id } } },
					} );
					const lineItems = lineItemsRead?.items;
					
					const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
						query    : AgreementsRead,
						variables: { options: { limit: 1000, filter: { order: invoice.id } } },
					} );
					const agreements = agreementsRead?.items;
					await mutateGraphQL<MutationInvoiceWriteArgs>( {
						mutation : InvoiceWrite,
						variables: {
							id    : invoiceDuplicate.id,
							method: 'Copied Invoice Template',
							input : {
								standingDate: invoice.standingDate ? invoiceDuplicate.standingDate : null,
								dueDate     : invoice.dueDate ? invoiceDuplicate.dueDate : null,
								serviceDate : invoice.serviceDate ? invoice.serviceDate : null,
								prices      : !isEmpty( invoiceDuplicate.prices )
									? invoiceDuplicate.prices.filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' )
										.map( ( { id } ) => ( { id } ) )
									: [],
								agreements  : agreements?.map( ( agreement ) => ( {
									...pick( agreement, [
										'title',
										'body',
										'requireSignature',
										'expiration',
									] ),
								} ) ),
								metadata    : {
									...invoiceDuplicate.metadata,
									sendReminder : staff.company.metadata?.dueReminder,
									paymentNote  : null,
									signatureLine: staff.company.metadata?.signatureLine,
									stock        : false,
								},
								lineItems   : lineItems?.map( ( lineItem ) => ( {
									...pick( lineItem, [
										'name',
										'price',
										'image',
										'unit',
										'quantity',
										'tax',
										'sequence',
										'orderTax',
									] ),
									metadata      : omit( lineItem.metadata, lineItemPropertiesToOmit ),
									description   : `${lineItem.description || ''}`,
									modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
									prices        : lineItem.prices?.map( ( price ) =>
										pick( price, [
											'name',
											'isPercent',
											'value',
											'quantity',
											'metadata',
										] ) ),
									uom           : lineItem.uom?.id || null,
									item          : lineItem.item?.id || null,
									category      : lineItem.category?.id || null,
								} ) ),
							},
						},
					} );
				} catch ( e ) {
					// showModal( SubscriptionModal, { maxWidth: 'xs' } );
					throw e;
				}
			},
		}, !isCloverDevice && {
			name   : t( 'common:pdf' ),
			icon   : <PictureAsPdfIcon/>,
			props  : {
				component: PageLinkComponent,
				href     : `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${invoice.id}/invoice`,
				target   : '_blank',
			},
			onClick: async () => {
				window.open( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${invoice.id}/invoice?timezone=${timezone}`, '_blank' );
				
				await logWrite( {
					company   : invoice.company.id,
					name      : 'PDF',
					method    : `Template PDF For ${type}`,
					documentId: invoice.id,
					user      : user?.id,
					table     : 'INVOICE',
				} );
				
			},
		}, editable && {
			name   : t( 'common:delete' ),
			icon   : <DeleteIcon/>,
			onClick: async () => {
				const value = await confirmDialog( {
					title  : t( 'common:delete' ),
					message: t( 'common:delete-confirmation' ),
				} );
				if ( !value ) return;
				await mutateGraphQL<MutationInvoiceWriteArgs>( {
					mutation : InvoiceWrite,
					variables: {
						id    : invoice.id,
						method: 'Deleted Invoice',
						input : {
							deletedAt: new Date(),
							number   : invoice.number,
						},
					},
				} );
				await deleteMergedTo( [ invoice ] );
				
				const loweredType = upperFirst( toLower( invoice.type ) );
				enqueueSnackbar( `${loweredType} ${t( 'common:delete-success' )}` );
			},
		},
	] as ActionProps[];
}
