import Actions, { ActionProps } from '@/components/actions';
import CSVImportModal from '@/components/fileUploading/CSVImportModal';
import OpenInvoicesDialog from '@/components/openInvoices';
import PageLinkComponent from '@/components/page/linkComponent';
import { useGraphQL } from '@/data';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CustomFieldsRead } from '@/data/commerce/customField.graphql';
import { InvoiceTemplateCSVString, InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { GatewaysRead } from '@/data/gateway.graphql';
import { PricesRead } from '@/data/management/price.graphql';
import useImportFields from '@/pages/dashboard/commerce/components/csvImportData';
import { getNewCommerceCompanyLocation } from '@/pages/dashboard/commerce/orders/actions/pageWrapperActions';
import RequirePinCodeModal from '@/pages/dashboard/settings/security/requirePinCodeModal';
import { useCloverLocations, useLocations } from '@/pages/formSelects/locationSelect';
import { getClientForMerchant } from '@/pages/tender/[id]/drawersActions/clientHelpers';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import {
	Client,
	MutationInvoiceWriteArgs,
	Order,
	PriceBase,
	QueryAgreementsReadArgs,
	QueryCustomFieldsReadArgs,
	QueryGatewaysReadArgs,
	QueryInvoiceTemplateCsvStringArgs,
	QueryPricesReadArgs,
} from '@/types/schema';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import {
	AddCircle as AddCircleIcon,
	DescriptionRounded as DescriptionRoundedIcon,
	FileCopy as FileCopyIcon,
	FileDownload as FileDownloadIcon,
	FileUpload as FileUploadIcon,
	MarkEmailUnread as MarkEmailUnreadIcon,
	ReceiptRounded as ReceiptRoundedIcon,
	Sync as SyncIcon,
} from '@mui/icons-material';
import { Link, Stack, Theme, useMediaQuery } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { add } from 'date-fns';
import { omit, pick } from 'lodash-es';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceCreateAgreementsRead } from '../invoiceGQL';
import SyncQBInvoices from '../syncQBInvoices';
import InvoiceTemplateTable from '../templateTable';

export function useCreateNewInvoice(
	client?: Client,
	houseAccountId?: string,
	disableRouting?: boolean,
): () => Promise<Order> {
	const { user, staff } = useUserInfo();
	const router = useRouter();
	const queryClient = useQueryClient();
	
	const [ locations ] = useLocations();
	const [ cloverLocations ] = useCloverLocations();
	const location = getNewCommerceCompanyLocation( staff, locations, cloverLocations );
	const metadata = staff?.company?.metadata;
	
	return async (): Promise<Order> => {
		try {
			const { pricesRead } = await queryGraphQL<QueryPricesReadArgs>( {
				query    : PricesRead,
				variables: {
					options: {
						limit : 1,
						filter: {
							company   : staff?.company?.id,
							metadata  : { companyFee: true },
							isSavedFee: true,
							order     : null,
						},
					},
				},
			} );
			const { customFieldsRead } = await queryGraphQL<QueryCustomFieldsReadArgs>( {
				query    : CustomFieldsRead,
				variables: {
					options: {
						limit : 30,
						filter: {
							company: staff?.company?.id,
							order  : null,
						},
					},
				},
			} );
			const customFields = customFieldsRead?.items;
			
			const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
				query    : InvoiceCreateAgreementsRead,
				variables: {
					options: {
						limit : 1,
						filter: {
							isDefault: true,
						},
					},
				},
			} );
			const agreements = agreementsRead?.items;
			
			if ( client ) {
				client = await getClientForMerchant( { companyLocation: location }, client, staff );
			}
			
			const { invoiceWrite } = await mutateGraphQL<MutationInvoiceWriteArgs>( {
				mutation : InvoiceWrite,
				variables: {
					customNumber: Boolean( staff.company.metadata?.customNumber ),
					method      : 'New Invoice',
					input       : {
						staff          : staff?.id || null,
						taxPercent     : staff.company?.metadata.tax,
						companyLocation: location?.id || null,
						policy         : location?.policy?.id || null,
						standingDate   : new Date(),
						dueDate        : add( new Date(), { days: metadata?.dueDate || metadata?.serviceDateDue || 0 } ),
						serviceDate    : new Date(),
						type           : 'INVOICE',
						client         : client?.id || null,
						houseAccount   : houseAccountId || null,
						customFields   : customFields?.map( ( customField ) => ( {
							...pick( customField, [ 'name', 'value' ] ),
							company: staff.company.id,
						} ) ),
						agreements     : agreements?.map( ( agreement ) => ( {
							...pick( agreement, [
								'title',
								'body',
								'requireSignature',
								'expiration',
							] ),
							company: staff.company.id,
						} ) ),
						metadata       : {
							enableCashDiscount  : staff.company.metadata?.cashDiscount > 0,
							signatureLine       : staff.company.metadata?.signatureLine,
							sendReminder        : staff.company.metadata?.dueReminder,
							hideTips            : staff.company.metadata?.hideTips,
							enablePartialPayment: staff.company.metadata?.enablePartialPayment,
							requireSignature    : staff.company.metadata?.requireSignature,
							disableHousePayment : true,
						},
						prices         : pricesRead?.items?.map( ( price: PriceBase ) => ( {
							...omit( price, [ 'id', 'company' ] ),
						} ) ),
					},
				},
			} );
			if ( !disableRouting ) {
				await router.push( `/dashboard/commerce/invoices/${invoiceWrite.id}/edit` );
				await queryClient.invalidateQueries( [ 'user' ] );
				await queryClient.invalidateQueries( [ 'company' ] );
			}
			return invoiceWrite;
		} catch ( e ) {
			console.error( e );
			throw e;
		}
	};
}

export default function InvoicePageWrapperActions( { actionProps }: { actionProps?: ActionProps[] } ) {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const creatable = usePermissions( permissions.invoices.write );
	const { staff, user } = useUserInfo();
	const createNewInvoice = useCreateNewInvoice();
	const { headers, headerValues, setData } = useImportFields( 'invoice' );
	const [ locations ] = useLocations();
	const [ cloverLocations ] = useCloverLocations();
	const linkRef = useRef( null );
	const superAdmin = isSuperAdmin( user );
	
	const location = cloverLocations?.[ 0 ] || locations.find( ( location ) => location.gateway?.external === 'CLOVER' && location.gateway?.active ) || locations?.[ 0 ];
	
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	const { data } = useGraphQL<QueryGatewaysReadArgs>( {
		query    : GatewaysRead,
		queryKey : [ 'gatewaysRead' ],
		variables: {
			options: {
				filter: {
					external: 'QUICKBOOKS',
					active  : true,
				},
			},
		},
	}, { keepPreviousData: true } );
	const qbGateway = data?.gatewaysRead?.items?.[ 0 ];
	
	return (
		<Stack direction='row' spacing={1}>
			<Link ref={linkRef} sx={{ display: 'none' }}>{t( 'common:csv-link' )}</Link>
			<Actions
				separated
				max={isMobile ? 2 : 3}
				items={[ /*creatable && isCloverDevice && {
					name       : t( 'commerce:new-invoice' ),
					onClick    : () => showModal( RegisterAppModal, { maxWidth: 'sm' } ),
					icon       : <AddCircleIcon/>,
					buttonProps: { variant: 'contained', color: 'primary' },
				},*/ creatable && {
					name       : t( 'commerce:new-invoice' ),
					icon       : <AddCircleIcon/>,
					buttonProps: { variant: 'contained', color: 'primary' },
					onClick    : createNewInvoice,
				}, creatable && {
					name   : t( 'commerce:open-invoice' ),
					icon   : <MarkEmailUnreadIcon/>,
					onClick: () => showModal( OpenInvoicesDialog, { variant: 'fullPageDialog' } ),
				}, creatable && qbGateway && {
					name   : t( 'commerce:sync-qb-invoices' ),
					icon   : <SyncIcon/>,
					onClick: async () => showModal( SyncQBInvoices, { maxWidth: 'sm' }, { gateway: qbGateway } ),
				}, creatable && {
					name       : t( 'common:statement' ),
					icon       : <DescriptionRoundedIcon/>,
					buttonProps: { color: 'primary' },
					onClick    : () => {
						const creditsPinRequired = staff.company.hasPinCode && staff.company.pinCodeData?.viewClientStatement;
						if ( creditsPinRequired ) {
							showModal( RequirePinCodeModal, { maxWidth: 'sm' }, {
								onSubmit: ( isApproved ) => {
									if ( isApproved ) {
										showModal( OpenInvoicesDialog, { variant: 'fullPageDialog' }, { clientStatement: true } );
									}
								},
							} );
						} else {
							showModal( OpenInvoicesDialog, { variant: 'fullPageDialog' }, { clientStatement: true } );
						}
					},
				}, creatable && {
					name       : t( 'commerce:house-account-statements' ),
					icon       : <ReceiptRoundedIcon/>,
					buttonProps: { color: 'primary' },
					props      : {
						component: PageLinkComponent,
						href     : '/dashboard/management/houseAccounts/invoice',
					},
				}, creatable && {
					name       : t( 'common:templates' ),
					icon       : <FileCopyIcon/>,
					buttonProps: { variant: 'outlined', color: 'primary' },
					onClick    : () => showModal( InvoiceTemplateTable, { maxWidth: 'lg' } ),
				}, !isMobile && creatable && {
					name   : t( 'commerce:import' ),
					icon   : <FileUploadIcon/>,
					onClick: () => showModal( CSVImportModal, { maxWidth: 'sm' }, { headers, headerValues, setData } ),
				}, !isMobile && creatable && {
					name   : `${t( 'management:export' )} ${t( 'common:template' )}`,
					icon   : <FileDownloadIcon/>,
					onClick: async () => {
						
						const { invoiceTemplateCSVString } = await queryGraphQL<QueryInvoiceTemplateCsvStringArgs>( {
							query    : InvoiceTemplateCSVString,
							variables: {
								locationId: location?.id,
								policyId  : location?.policy?.id,
								type      : 'INVOICE',
							},
						} );
						
						if ( invoiceTemplateCSVString ) {
							const blob = new Blob( [ invoiceTemplateCSVString ], { type: 'text/csv;charset=utf-8;' } );
							const url = URL.createObjectURL( blob );
							const fixedEncodedURI = url.replace( /#/g, '%23' );
							linkRef.current.setAttribute( 'href', fixedEncodedURI );
							linkRef.current.setAttribute( 'download', 'invoice-template' );
							linkRef.current.click();
						}
					},
				} ]}
				{...actionProps}
			/>
		</Stack>
	);
}
